import { VIEW_STATE, VIEW_CITY, VIEW_PINCODE, MERCHANT_LOGIN, MERCHANT_SIGNUP, FORGOT_PASSWORD, OTP_PASSWORD,MERCHANT_BRANDS,VIEW_COURIER_OVER, RESET_PASSWORD, ADMIN_LOGIN, VIEW_COURIER, VIEW_MERCHANT,VIEW_CITY_STATE, VIEW_MERCHANT_REMMITANCE, VIEW_MERCHANT_COURIER, VIEW_CHANNEL_BRAND, VIEW_FREIGHTOVER, VIEW_REMITTANCEOVER, WALLET_BALANCE, VIEW_COURIER_REMMITANCE,VIEW_CARDOVER,VIEW_NDR_CARDOVER,VIEW_ORDERSUMMARY,VIEW_M_PERFORMANCEOVERVIEW,VIEW_M_SHIPMENTSTAUS, VIEW_DAILY_SHIP_SUMMARY, VIEW_SHIPMENT_SUMMARY, VIEW_DASH_COURIER_REMITTANCE,VIEW_DASH_FREIGHT_REMITTANCE,VIEW_DASH_PENDING_SHIPMENT, VIEW_DASH_PICKUP_PENDING,VIEW_DASH_NOT_ATTEMPTED, VIEW_MERGE_ACCOUNT, VIEW_OVERALL_PERFORMANCE } from "../actions/Types";
  
  const initialState = [];
  
  const DataReducer = (stateData = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

      case VIEW_STATE: 
        return {
          ...stateData,
          myAllState: payload,
        };

      case VIEW_CITY:  
        return {
          ...stateData,
          viewAllCity: payload,
        };

      case VIEW_PINCODE:
        return {
          ...stateData,
          viewAllPincode: payload,
        };
      case MERCHANT_BRANDS:
        return {
          ...stateData,
          viewAllMerchantBrands: payload,
        };

      case VIEW_COURIER: 
        return {
          ...stateData,
          viewAllCourier: payload,
        };

      case VIEW_MERCHANT: 
        return {
          ...stateData,
          viewAllMerchant: payload,
        };
              
      case VIEW_MERCHANT_REMMITANCE: 
        return {
          ...stateData,
          MerchantRemittance: payload,
        };
      case VIEW_COURIER_REMMITANCE: 
        return {
          ...stateData,
          courierRemittance: payload,
        };
      case VIEW_MERCHANT_COURIER: 
        return {
          ...stateData,
          shipmentMerchantCourier: payload,
        };

      case MERCHANT_LOGIN:
        // return [...stateData, payload];
        return {
          ...stateData,
          merchantLoginData: payload,
        };
        
      case MERCHANT_SIGNUP:
        // return [...stateData, payload];
        return {
          ...stateData,
          merchantSignupData: payload,
        };
      case VIEW_CHANNEL_BRAND:
        return {
          ...stateData,
          channelBrandData: payload,
        };
        case VIEW_FREIGHTOVER: 
        return {
          ...stateData,
          viewAllFreightOver: payload,
        };
        case VIEW_CARDOVER: 
        return {
          ...stateData,
          viewAllCardOver: payload,
        };
        case VIEW_NDR_CARDOVER: 
        return {
          ...stateData,
          viewNdrCardOver: payload,
        };
        case VIEW_DAILY_SHIP_SUMMARY: 
        return {
          ...stateData,
          viewDailyShipSummary: payload,
        };
        case VIEW_SHIPMENT_SUMMARY: 
        return {
          ...stateData,
          viewShipmentSummary: payload,
        };
        case VIEW_OVERALL_PERFORMANCE: 
        return {
          ...stateData,
          viewOverAllPerformance: payload,
        };
        case VIEW_DASH_COURIER_REMITTANCE: 
        return {
          ...stateData,
          viewDashCourierRemittance: payload,
        };
        case VIEW_DASH_FREIGHT_REMITTANCE: 
        return {
          ...stateData,
          viewDashFreightRemittance: payload,
        };
        case VIEW_DASH_PENDING_SHIPMENT: 
        return {
          ...stateData,
          viewDashPendingShipments: payload,
        };
        case VIEW_DASH_PICKUP_PENDING: 
        return {
          ...stateData,
          viewDashPickupPending: payload,
        };
        case VIEW_DASH_NOT_ATTEMPTED: 
        return {
          ...stateData,
          viewDashNotAttempted: payload,
        };
        case VIEW_MERGE_ACCOUNT: 
        return {
          ...stateData,
          viewMergeAccount: payload,
        };
        case VIEW_ORDERSUMMARY: 
        return {
          ...stateData,
          viewOrderSummary: payload,
        };
        case VIEW_M_PERFORMANCEOVERVIEW: 
        return {
          ...stateData,
          viewPerformanceOverM: payload,
        };
        case VIEW_M_SHIPMENTSTAUS: 
        return {
          ...stateData,
          viewShipmentStatusM: payload,
        };
        case VIEW_CITY_STATE: 
        return {
          ...stateData,
          viewCityState: payload,
        };
        case VIEW_COURIER_OVER: 
        return {
          ...stateData,
          viewCourierOverview: payload,
        };
        case WALLET_BALANCE: 
        return {
          ...stateData,
          totalBalance: payload,
        };
        case VIEW_REMITTANCEOVER: 
        return {
          ...stateData,
          viewAllRemittanceOver: payload,
        };
        case "RESET":
        return {
          ...initialState
        }
      case FORGOT_PASSWORD:
        return [...stateData, payload];

      case OTP_PASSWORD:
        return [...stateData, payload];

      case RESET_PASSWORD:
        return [...stateData, payload];

      case ADMIN_LOGIN:
        return [...stateData, payload];
  
      default:
        return stateData;
    }
  };
  
  export default DataReducer;