import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';

const NDRIVRResponse = (props) => {
    return (
        <div className="PerformanceOverview">
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <div className="page-title">NDR IVR Response</div>  
                </div>
                <CardBody className="p-0">
                    <div className="fixHeader-table">
                        <Table responsive className="text-center custom-table mb-0">
                        <thead className="sticky-top bg-white">
                            <tr>
                                <th className="text-nowrap">31 Days</th>
                                <th className="text-nowrap">Customer Refused</th>
                                <th className="text-nowrap">Customer Not Available</th>
                                <th className="text-nowrap">Wrong Address/Pin Code</th>
                                <th className="text-nowrap">Premise Lock</th>
                                <th className="text-nowrap">Requested Delay Delivery</th>
                                <th className="text-nowrap">Cash Not Ready</th>
                            </tr>
                            <tr>
                                <th className="text-nowrap">Total</th>
                                <th className="text-nowrap">0</th>
                                <th className="text-nowrap">0</th>
                                <th className="text-nowrap">0</th>
                                <th className="text-nowrap">0</th>
                                <th className="text-nowrap">0</th>
                                <th className="text-nowrap">0</th>
                            </tr>
                        </thead>
                        <tbody>  
                            <tr>
                                <td className="text-nowrap">06-10-2021</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr> 
                            <tr>
                                <td className="text-nowrap">06-10-2021</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr> 
                            <tr>
                                <td className="text-nowrap">06-10-2021</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>  
                        </tbody>
                    </Table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default NDRIVRResponse;