import React, {useState} from 'react';
import { Card, CardBody,CardHeader, Label, FormGroup } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

const ShipmentData = (props) => {

    const [city, setCity] = useState(true);
    const [state, setState] = useState(false);
    const [product, setProduct] = useState(false);
    const [msgwise, setMsgwise] = useState("City");

    const cityData = () => {
        setCity(!city);
        setMsgwise("City");
        setState(false);
        setProduct(false);
    }
    const stateData = () => {
        setState(!state);
        setMsgwise("State");
        setCity(false);
        setProduct(false);
    }
    const productData = () => {
        setProduct(!product);
        setMsgwise("Product");
        setState(false);
        setCity(false);
    }

    const data = {
        labels: ['Hyderabad', 'Bangalore', 'Mumbai', 'Chennai', 'Pune', 'Bengaluru', 'kolkata'],
        datasets: [
          {
            label: 'Shipment (counts)',
            data: [0, 0, 0, 0, 0, 0, 0,0],
            backgroundColor: [
            'rgb(41,116,255)',
            'rgb(253,172,65)',
            'rgb(255,91,92)',
            'rgb(57,218,138)',
            'rgb(0,207,221)',
            'rgb(71,95,123)',
            'rgb(229,229,229)'
            ],
          },
          
        ],
       
        
      };

      const options = {
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: msgwise+' Wise Shipments'
          },
          legend: {
            display: false,
            position: "top",
         }
        },
      };


  

    return (
        <div className="ShipmentData">
            <Card className="border-0 shadow">
                <CardHeader className="page-header">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="d-flex flex-wrap">
                            <FormGroup className="mr-sm-4">
                                <span className="form-check2 mr-2">
                                    <input name="order_type" type="radio" id="All"/>
                                    <Label className="mx-2" for="All">All</Label>
                                </span>
                            </FormGroup>
                            <FormGroup className="mr-sm-4">
                                <span className="form-check2 mr-2">
                                    <input name="order_type" type="radio" id="COD"/>
                                    <Label className="mx-2" for="COD">COD</Label>
                                </span>
                            </FormGroup>
                            <FormGroup className="mr-4">
                                <span className="form-check2 mr-2">
                                    <input name="order_type" type="radio" id="Prepaid"/>
                                    <Label className="mx-2" for="Prepaid">Prepaid</Label>
                                </span>
                            </FormGroup>
                        </div> 

                        <div className="d-flex flex-wrap">
                            <FormGroup className="mr-sm-4">
                                <span className="form-check2 mr-2">
                                    <input name="place_wise" type="radio" id="productdata" onChange={productData}/>
                                    <Label className="mx-2" for="productdata">Product Wise Data</Label>
                                </span>
                            </FormGroup>
                            <FormGroup className="mr-sm-4">
                                <span className="form-check2 mr-2">
                                    <input name="place_wise" type="radio" id="citydata" onChange={cityData} checked={city} />
                                    <Label className="mx-2" for="citydata">City Wise Data</Label>
                                </span>
                            </FormGroup>
                            <FormGroup className="">
                                <span className="form-check2 mr-2">
                                    <input name="place_wise" type="radio" id="statedata" onChange={stateData} />
                                    <Label className="mx-2" for="statedata">State Wise Data</Label>
                                </span>
                            </FormGroup>
                        </div>
                    </div>
                </CardHeader>

                <CardBody>
                <div style={{height:"200px"}}>
                    <Bar data={data} options={options}/>
                </div>
                </CardBody>

            </Card>            
        </div>
    );
}

export default ShipmentData;