import React from 'react';
import {Button,Modal, ModalHeader, ModalBody, Form, Row, Col, FormGroup, Label } from 'reactstrap';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditEmailTemplate = (props) => {
  

  return (
          <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" scrollable={true}>
            <ModalHeader toggle={props.toggle}>Edit Email Content</ModalHeader>
            <ModalBody>
            <Form>
                    <Row className="mx-0 justify-content-centent">
                      <Col sm={12}>
                          <FormGroup>
                            <Label for="content_ttl">Content Title*</Label>
                            <input className="form-control-sm form-control" placeholder="Enter Content Title*" id="content_ttl" />
                          </FormGroup>
                      </Col>
                      <Col sm={12}>
                          <FormGroup>
                            <Label for="subject">Subject*</Label>
                            <input className="form-control-sm form-control" placeholder="Enter Subject*" id="subject" />
                          </FormGroup>
                      </Col>
                      <Col sm={12}>
                          <FormGroup>
                            <Label>Email Body*</Label>
                            <Editor
                              toolbarClassName="mb-0"
                              wrapperClassName=""
                              editorClassName="editorClassName border border-top-0"
                            />
                          </FormGroup>
                      </Col>
                      <Col sm={12}>
                          <FormGroup check className="pl-0">
                            <Label>Status</Label>
                            <div>
                              <span className="mr-2">
                               <Label check>
                                  <input className="mx-2" type="radio" value="1" id="active_add" defaultChecked />
                                  Active
                                </Label>
                              </span>
                              <span>
                                <Label check>
                                  <input className="mx-2" type="radio" value="0" id="inactive_add" />
                                  InActive
                                </Label>
                              </span>
                            </div>
                          </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                      </Col>
                    </Row>
                  </Form>  
            </ModalBody>
        </Modal>
  );
}

export default EditEmailTemplate;