import React, {useEffect} from 'react';
import { Row, Col, Button,Label, FormGroup,Form,} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { StateList } from './StateList';
import Swal from 'sweetalert2'
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';

const EditCity = (props) => {
   
const { register:editCitydata, handleSubmit:cityEditSubmit, formState: { errors }, reset} = useForm();
useEffect(() => {
  props.cityDate[0].is_metro = String(props.cityDate[0].is_metro)
  reset(props.cityDate[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
const onEditCitySubmit = (data) => {
  props.setLoader !== undefined && props.setLoader(true);
  let dataAdd={"name":data.name,"state_id":data.state_id,"is_metro":data.is_metro};
    fetch(`${process.env.REACT_APP_GET_CITY}/update/${props.cityDate[0].id}`,{
      method:'POST',
      headers:{
        "Content-Type":'application/json',
        "Accept":'application/json',
        "token": window.btoa(baseString)
      },
      body:JSON.stringify(dataAdd)
    }).then((resp)=>{
      resp.json().then((result)=>{
        props.setLoader !== undefined && props.setLoader(false);
        props.cityListing !== undefined && props.cityListing();
        if(result.error === false){
          props.seteditModal(false)
          Swal.fire({
            title: 'Success',
            text: result.message,
            icon: 'success',
          })
        }
      });
    }).catch(err => {
        props.setLoader !== undefined && props.setLoader(false);
        errorHandling(err,"");
  });
};

  return (
    <Form onSubmit={cityEditSubmit(onEditCitySubmit)}>
    <Row className="mx-0 justify-content-center addModal">
        <Col sm={12}>
        <FormGroup>
        <Label for="state_edit">Select State<span className="text-danger">*</span></Label>
        <div className="position-relative">
            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
            <select {...editCitydata("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_edit">
                <option value="">select State</option>
                {StateList.map((item, index) => (
                    <option value={item.state_id}>{item.state_name}</option>
                ))
            }
            </select>
            {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
        </div>
        </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup>
            <Label for="city_edit">Enter City<span className="text-danger">*</span></Label>
            <input {...editCitydata("name",{ required: 'Please Enter City.',
              pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid City'} })} className="form-control-sm form-control" placeholder="Enter City" id="city_edit" />
            {errors.name && <span className="text-danger d-block error">{errors.name.message}</span>}
            </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup className="mb-0">
              <Label>Is Metro<span className="text-danger">*</span></Label>
              <div className="d-flex">
              <span className="form-check2 mr-2">                  
                  <input type="radio" {...editCitydata("is_metro", { required: true})} value="1" id="is_metro_yes" className="mx-2" defaultChecked ={props.cityDate[0].is_metro !== null ? true : false} />
                  <Label className="mx-2" for="is_metro_yes">Yes</Label>                               
              </span>
              <span className="form-check2">
                  <input type="radio" {...editCitydata("is_metro", { required: true})} value="0" id="is_metro_no" className="mx-2" defaultChecked ={props.cityDate[0].is_metro !== null ? true : false} />
                  <Label className="mx-2" for="is_metro_no">No</Label>
              </span>
              </div>
            </FormGroup>
        </Col>
        <Col sm={12} className="text-right">
        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
        </Col>
    </Row>
    </Form>
  );
}

export default EditCity;