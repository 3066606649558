import React, {useState} from 'react';
import { Row, Col, Button, Label, Spinner, FormGroup, Form} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { StateList } from './StateList';
import Swal from 'sweetalert2'
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';

const AddCity = (props) => {

  const [loader, setLoader] = useState(false)

  
  const { register:addCity, handleSubmit:citySubmit, formState: { errors }} = useForm();
  const onAddCitySubmit = (data) => {
    setLoader(true)
    let dataAdd={"name":data.city,"state_id":data.state_id,"is_metro":data.is_metro};
      fetch(`${process.env.REACT_APP_ADD_CITY}`,{
        method:'POST',
        headers:{ "Content-Type":'application/json',"Accept":'application/json',
        "token": window.btoa(baseString)
      },
        body:JSON.stringify(dataAdd)
      }).then((resp)=>{
        resp.json().then((result)=>{
          setLoader(false);
          if(result.error === false){
            props.setAddModal(false)
            props.cityListing !== undefined && props.cityListing();
            Swal.fire({ title: 'Success', text: result.message, icon: 'success'})
          }else{
            Swal.fire({icon:"error", title:"Error", text:result.message})
          }
        });
      }).catch(err => {
          setLoader(false);
          errorHandling(err,"");
      });
  };
  
  return (
    <Form onSubmit={citySubmit(onAddCitySubmit)}>
    <Row className="mx-0 justify-content-center addModal">
    {loader && <div className="formLoader"><Spinner /></div>}
        <Col sm={12}>
        <FormGroup>
        <Label for="state_add">Select State<span className="text-danger">*</span></Label>
        <div className="position-relative">
            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
            <select {...addCity("state_id",{ required: 'Please Select State'})}  className="form-control-sm form-control" id="state_add">
                <option value="">Select State</option>
                {StateList.map((item, index) => (
                    <option value={item.state_id}>{item.state_name}</option>
                ))
            }
            </select>
            {errors.state_id && <span className="text-danger d-block error">{errors.state_id.message}</span>}
        </div>
        </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup>
            <Label for="city_add">Enter City<span className="text-danger">*</span></Label>
            <input {...addCity("city",{ required: 'Please Enter City.',
              pattern: {value:/^[a-z ,.'-]+$/i,message:'Please Enter Valid City'} })}  className="form-control-sm form-control" placeholder="Enter City" id="city_add"/>
            {errors.city && <span className="text-danger d-block error">{errors.city.message}</span>}
            </FormGroup>
        </Col>
        <Col sm={12}>
            <FormGroup className="mb-0">
            <Label>Is Metro<span className="text-danger">*</span></Label>
            <div className="d-flex">
                <span className="form-check2 mr-2">
                <input {...addCity("is_metro", { required: true })} type="radio" value="1" id="active_add" defaultChecked/>
                <Label className="mx-2" for="active_add">Yes</Label>
                </span>
                <span className="form-check2">
                <input {...addCity("is_metro", { required: true })} type="radio" value="0" id="inactive_add" />
                <Label className="mx-2" for="inactive_add">No</Label>
                </span>
            </div>
            </FormGroup>                          
        </Col>
        <Col sm={12} className="text-right">
        <Button className="ctm-btn btn-sm" color="primary">Save</Button>
        </Col>
    </Row>
    </Form>  
                
  );
}

export default AddCity;